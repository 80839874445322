import Head from 'next/head';

export default function CovidHead() {
  return (
    <Head>
      <title>MedStar Covid-19</title>
      <meta name="robots" content="noindex" />
      <link rel="icon" href="/favicon.ico" />
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID}`}
      />

      <script
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID}');
        `,
        }}
      />
    </Head>
  );
}
