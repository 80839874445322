/** @jsximport 'theme-ui' */
// import { jsx } from 'theme-ui';
import * as Sentry from '@sentry/browser';
import { ThemeProvider } from 'theme-ui';
import theme from '../theme';
import '../styles/globals.css';
import Head from '../src/components/head';
Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN_FRONTEND,
  environment: process.env.NODE_ENV,
  release: process.env.NEXT_PUBLIC_GIT_TAG_VERSION,
  enabled: process.env.NODE_ENV === 'production',
});

export default function MyApp({ Component, pageProps }) {
  return (
    <ThemeProvider theme={theme}>
      <Head />
      <Component {...pageProps} />
    </ThemeProvider>
  );
}
